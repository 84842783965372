import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JoinRoomComponent } from './views/join-room/join-room.component';
import { GameRoomComponent } from './views/game-room/game-room.component';


const routes: Routes = [
  { path: 'game', component: GameRoomComponent },
  { path: 'join', component: JoinRoomComponent },
  { path: '', redirectTo: '/join', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
