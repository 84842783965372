import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ColyseusService } from './services/colyseus.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { FormsModule } from '@angular/forms';
import { JoinRoomComponent } from './views/join-room/join-room.component';
import { GameRoomComponent } from './views/game-room/game-room.component';

import { ReactiveFormsModule } from '@angular/forms'
import { MatCardModule } from '@angular/material/card';

import { PlayerCardComponent } from './player/player-card/player-card.component';


@NgModule({
  declarations: [
    AppComponent,
    JoinRoomComponent,
    GameRoomComponent,
    PlayerCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule

  ],
  providers: [ColyseusService],
  bootstrap: [AppComponent]
})
export class AppModule { }
