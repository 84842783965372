import { Component, OnInit } from '@angular/core';
import { ColyseusService, } from './../../services/colyseus.service';
import { Router } from '@angular/router';
import { SpaceCutterActions } from './../../services/models/actions.model';


@Component({
  selector: 'app-game-room',
  templateUrl: './game-room.component.html',
  styleUrls: ['./game-room.component.scss']
})
export class GameRoomComponent implements OnInit {
  //Game States
  STATE_WAITING_FOR_PLAYERS: string = 'waiting_for_players';
  STATE_WAITING_FOR_INPUT: string = 'waiting_for_input';
  STATE_RESOLVING_TURN: string = "resolving_turn";
  STATE_END_OF_ROUND: string = "end_of_round";

  public serverState: string = this.STATE_WAITING_FOR_PLAYERS;


  constructor(private gameService: ColyseusService, private router: Router) {
    gameService.roomStateChange.subscribe((state: any) => {
      this.serverState = state.gameState;
    })
  }

  ngOnInit() {
  }

  public everyoneIsInThePlace() {
    console.log("send everyone is in");
    this.gameService.sendAction(SpaceCutterActions.EVERYBODY_IS_IN);
  }

  public startNextRound() {
    console.log("Starting Next round");
    this.gameService.sendAction(SpaceCutterActions.START_ROUND)
  }

  public goMine() {
    this.gameService.sendAction(SpaceCutterActions.CHOICE_MINE);
  }

  public goLaunch() {
    this.gameService.sendAction(SpaceCutterActions.CHOICE_LEAVE);

  }

}
