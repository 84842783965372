import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ColyseusService, } from './../../services/colyseus.service';
import { RoomAvailable } from 'colyseus.js/lib/Room';
import { Router } from '@angular/router';



@Component({
  selector: 'app-join-room',
  templateUrl: './join-room.component.html',
  styleUrls: ['./join-room.component.scss']
})

export class JoinRoomComponent implements OnInit {
  public loginForm: FormGroup;


  constructor(private gameService: ColyseusService, private router: Router) {

    gameService.roomStateChange.subscribe((state: any) => {
    })

    this.loginForm = new FormGroup({
      playerName: new FormControl(),
      roomcode: new FormControl()
    });
  }

  public ngOnInit() {


  }

  public joinRoom() {
    // console.log("JOINING ROOM");
    this.gameService.listRooms().then((availableRooms: RoomAvailable<any>[]) => {
      const targetRoom: RoomAvailable<any> = availableRooms.find((row: RoomAvailable<any>) => {
        return String(row.metadata.room_code).toLowerCase() === String(this.loginForm.get('roomcode').value).toLowerCase();
      })

      if (targetRoom) {
        this.gameService.joinPrivateRoom(targetRoom.metadata.room_id, { clint_type: "player", player_handle: this.loginForm.get('playerName').value }).then(() => {
          // We have joined the room Take us to the game room
          this.router.navigate(['/game', {}]);
        })
      } else {

        // Room Not Found
        console.log("Well this is akward, no room found");
      }
    }
    )
  }



}
