import { Injectable, Output, EventEmitter } from '@angular/core';
import { Client, Room } from 'colyseus.js';
import { Observable } from 'rxjs';
import { RoomAvailable } from 'colyseus.js/lib/Room';

@Injectable({
  providedIn: 'root'
})
export class ColyseusService {

  private colyseusClient: Client;

  public room: Room;
  @Output() roomReady = new EventEmitter()
  @Output() roomStateChange = new EventEmitter()

  private _clientSessionId: string;

  public playerObject: any;

  public myPlayer: any;

  // ? "ws://192.168.0.127:3552" // development (local)
  endpoint = (window.location.hostname.indexOf("herokuapp") === -1)
    ? "wss://space-cutters-dev.herokuapp.com" // development (local) 
    : `${window.location.protocol.replace("http", "ws")}//${window.location.hostname}` // production (remote)

  constructor() {
    this.colyseusClient = new Client(this.endpoint);

  }

  async connect(options: Object = null): Promise<Room> {
    return await this.colyseusClient.create('spacecutter', { 'client_type': 'cutter_host' });

  }

  private stateChanged(state: any) {
    this.roomReady.emit()
    this.roomStateChange.emit(state);

    //Update my player obj
    this.playerObject = state.players.find((player: any) => {
      return player.sessionId === this._clientSessionId;

    })
  }
  private onMessage(message: any) {
    if (message.session_id) {
      this._clientSessionId = message.session_id;
    }
    console.log("MESSAGE RECIEVED: " + JSON.stringify(message));
  }

  async joinPrivateRoom(roomId: string, options: Object = null): Promise<Room> {
    return await this.colyseusClient.joinById(roomId, options).then((room: Room) => {
      this.room = room;
      this.roomReady.emit();
      this.room.onStateChange((state: any) => {
        this.stateChanged(state);
      });
      this.room.onMessage((message) => {
        this.onMessage(message)
      });
      return room;
    })
    // return await this.colyseusClient.joinById(roomId, options);
  }

  async listRooms(): Promise<RoomAvailable<any>[]> {
    try {
      return await this.colyseusClient.getAvailableRooms<any>("spacecutter");
    } catch (e) {
      console.error(e);
    }

    // .catch(e => {
    //   console.error(e);
    // });
  }

  async sendAction(action: string): Promise<void> {
    console.log("SENDING ACTION: " + action);
    try {
      return await this.room.send({ "action": action });
    } catch (e) {
      console.error(e);
    }
  }

  // private async sendMsg(): Promise<any> {

  // }
}
