import { Component, OnInit } from '@angular/core';
import { ColyseusService } from './services/colyseus.service';
import { Room } from 'colyseus.js';
import { RoomAvailable } from 'colyseus.js/lib/Room';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  //Local States
  CONNECT_TO_SERVER: string = "connect_to_server";
  WAITING_FOR_PLAYERS: string = "waiting_for_players";
  GAME: string = "in_game";
  GAME_OVER: string = "game_over";


  public room: Room;

  public roomCode: string;
  public playerName: string;

  public theService: ColyseusService;

  title = 'web-phone-standin';


  constructor(private gameService: ColyseusService) {

    gameService.roomReady.subscribe(() => {
      //Now that the room is rady we can listen for state changes
      gameService.room.onStateChange((state) => {

      });
    });
  }

  public ngOnInit() {

  }

  public joinRoom() {
    console.log("JOINING ROOM");
    // this.gameService.listRooms().then((availableRooms: RoomAvailable<any>[]) => {
    //   const targetRoom: RoomAvailable<any> = availableRooms.find((row: RoomAvailable<any>) => {
    //     return String(row.metadata.room_code).toLowerCase() === String(this.loginForm.get('roomcode').value).toLowerCase();
    //   })

    //   if (targetRoom) {
    //     this.gameService.joinPrivateRoom(targetRoom.metadata.room_id, { clint_type: "player", player_handle: this.loginForm.get('playerName').value })
    //   } else {
    //     // Room Not Found
    //     console.log("Well this is akward, no room found");
    //   }
    // }
    // )
  }

}
